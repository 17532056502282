/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/submodules/slider/slider.scss ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************/
.event-description {
  overflow-wrap: break-word;
}

@media only screen and (min-width: 768px) {
  .slider-area .description {
    display: block; /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 215px;
    line-height: 43px;
  }
  body .height-950 {
    height: 950px;
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  .slider-style-4.variation-2 .inner .title {
    font-size: 24px !important;
  }
  .slider-style-4 .inner .description {
    font-size: 18px;
    line-height: 22px;
  }
  .slider-area .height-950 {
    min-height: 430px;
    height: 430px;
    padding: 0;
  }
  .slider-dot.rn-slick-dot .slick-dots {
    bottom: 20px;
  }
  .slider-area a.btn-default, .slider-area button.btn-default, .slider-area div.btn-default {
    height: 40px;
    line-height: 36px;
    padding: 0 20px;
  }
  .slider-area .h-950 {
    height: inherit;
  }
}
@media only screen and (max-width: 375px) {
  .slider-style-4.variation-2 .inner .title {
    font-size: 18px !important;
  }
  .slider-style-4 .inner .description {
    font-size: 16px;
    line-height: 19px;
  }
  .slider-area .height-950 {
    min-height: 430px;
    padding: 0;
  }
}
.slider-area video {
  opacity: 0;
  transition: 200ms ease-in-out;
}

.slider-area video.loaded {
  opacity: 1;
}
